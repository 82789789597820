// @flow
import React from 'react';
import SelectCompany from './SelectCompanyView';
import type { Company } from '../../../../../models/companies/Company';

type Field = {
  id: number,
  companyId: ?number,
};

type BuyWish = {
  companyId: number,
};

type Props = {
  companies: Array<Company>,
  defaultData: Array<BuyWish>,
};

type State = {
  fields: Array<Field>,
};

export default class SelectCompaniesToBuyView extends React.Component<
  Props,
  State,
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: this.convertToFields(props.defaultData),
    };
  }

  onAddField() {
    const { fields } = this.state;
    let nextID = 0;
    if (fields.length > 0) {
      nextID = fields[fields.length - 1].id + 1;
    }

    this.setState({
      fields: [
        ...this.state.fields,
        {
          id: nextID,
          companyId: null,
        },
      ],
    });
  }

  onRemoveField(id: number) {
    this.setState({
      fields: [...this.state.fields.filter(c => c.id !== id)],
    });
  }

  convertToFields(buyWishes: Array<BuyWish>): Array<Field> {
    return buyWishes.map((buyWish, i) => ({
      id: i,
      companyId: buyWish.companyId,
    }));
  }

  render() {
    const { fields } = this.state;

    return (
      <div className="select-buy-form-wrapper">
        {fields.map(field => (
          <fieldset
            key={field.id}
            id={`fundinno_company_to_buy_${field.id}`}
            className="fieldset-row"
          >
            <SelectCompany
              companies={this.props.companies}
              inputName={`market_demand_questionnaire[fundinno_stock_buy_wishes_attributes][${field.id}][company_id]`}
              className="company-select"
              defaultCompanyId={field.companyId}
              placeholder="FUNDINNO銘柄"
            />
            <button
              type="button"
              onClick={() => this.onRemoveField(field.id)}
              className="icon-button"
            >
              <i className="fas fa-minus-circle" />
            </button>
          </fieldset>
        ))}
        <button
          type="button"
          onClick={() => this.onAddField()}
          className="icon-button"
        >
          <i className="fas fa-plus-circle" />
        </button>
      </div>
    );
  }
}
