// @flow
import JQueryView, { on } from '../common/JQueryView';
import { numberToCurrency } from '../../utils/commonUtils';

export default class FormView extends JQueryView {
  stockPrice: number;

  constructor(stockPrice: number, amount: number) {
    super('form');
    this.stockPrice = stockPrice;
    amount =
      amount == null || amount === 0
        ? $('#plus_projects_order_form_amount').val()
        : amount;
    this.updateTotalPrice(amount);
  }

  @on('change #plus_projects_order_form_amount')
  onChangeAmount(event: any) {
    const amount = event.currentTarget.value;
    this.updateTotalPrice(amount);
  }

  updateTotalPrice(amount: number) {
    amount = parseInt(amount, 10);

    if (isNaN(amount) || amount < 1) {
      return $('#total_price').text('');
    }

    var totalPrice = this.stockPrice * amount;

    $('#total_price').text(numberToCurrency(totalPrice));
  }
}
