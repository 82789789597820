// @flow
import JQueryView, { on } from '../common/JQueryView';

export default class IndexView extends JQueryView {
  constructor() {
    super('.index');
  }

  @on('click tr.link-row')
  onClickLinkCell(event: any) {
    const id = event.currentTarget.dataset.id;
    window.location.href = `/plus/projects/${id}`;
  }
}
