// @flow
import React from 'react';
// $FlowFixMe
import Select from 'react-select';
import type { SelectItem } from '../../../../../models/select/SelectItem';
import { fromCompany } from '../../../../../models/select/SelectItem';
import type { Company } from '../../../../../models/companies/Company';

type Props = {
  companies: Array<Company>,
  inputName: ?string,
  className: ?string,
  defaultCompanyId: ?number,
  placeholder: ?string,
};

type State = {
  selectedOption: any,
  options: Array<SelectItem>,
};

export default class SelectCompaniesView extends React.Component<Props, State> {
  selectRef: { current: any };

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: null,
      options: fromCompany(props.companies),
    };
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ selectedOption: this.defaultOption() });
  }

  handleChangeValue(data: any) {
    this.setState({
      selectedOption: data,
    });
  }

  defaultOption = () => {
    const { defaultCompanyId } = this.props;
    const { options } = this.state;
    return options.find(item => item.value === defaultCompanyId) || null;
  };

  // 事業者を選択時にEnter押すとPOSTリクエストが飛んでしまうため、そうならないようにEnter押下時の処理を追加
  onKeyDown = (event: SyntheticKeyboardEvent<HTMLDivElement>) => {
    const { select } = this.selectRef.current;
    const { focusedOption } = select.state;

    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (!select.props.menuIsOpen) {
          this.selectRef.current.setState({
            menuIsOpen: true,
          });
          break;
        } else {
          if (!focusedOption) return;
          if (select.isComposing) return;
          select.selectOption(focusedOption);
          break;
        }
    }
  };

  render() {
    return (
      <Select
        value={this.state.selectedOption}
        name={this.props.inputName}
        className={this.props.className}
        ref={this.selectRef}
        isClearable={true}
        onChange={val => this.handleChangeValue(val)}
        onKeyDown={this.onKeyDown}
        options={this.state.options}
        noOptionsMessage={() => '選択可能な銘柄がありません。'}
        placeholder={this.props.placeholder || ''}
      />
    );
  }
}
