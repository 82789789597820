// @flow
import React from 'react';
import { Input } from 'reactstrap';
import SelectCompany from './SelectCompanyView';
import type { Company } from '../../../../../models/companies/Company';

type Field = {
  id: number,
  companyId: ?number,
  wishedSellPrice: ?string,
};

type SellWish = {
  companyId: number,
  wishedSellPrice: string,
};

type Props = {
  companies: Array<Company>,
  wishedSellPrices: any,
  defaultData: Array<SellWish>,
};

type State = {
  fields: Array<Field>,
};

export default class SelectCompaniesToSellView extends React.Component<
  Props,
  State,
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: this.convertToFields(props.defaultData),
    };
  }

  onAddField() {
    const { fields } = this.state;
    let nextID = 0;
    if (fields.length > 0) {
      nextID = fields[fields.length - 1].id + 1;
    }

    this.setState({
      fields: [
        ...this.state.fields,
        {
          id: nextID,
          companyId: null,
          wishedSellPrice: null,
        },
      ],
    });
  }

  onRemoveField(id: number) {
    this.setState({
      fields: [...this.state.fields.filter(c => c.id !== id)],
    });
  }

  onKeyDown = (event: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  convertToFields(sellWishes: Array<SellWish>): Array<Field> {
    return sellWishes.map((sellWish, i) => ({
      id: i,
      companyId: sellWish.companyId,
      wishedSellPrice: sellWish.wishedSellPrice,
    }));
  }

  render() {
    const { fields } = this.state;
    const { wishedSellPrices } = this.props;

    return (
      <div className="sell-form-wrapper">
        {fields.map(field => (
          <fieldset
            key={field.id}
            id={`company_to_sell_${field.id}`}
            className="fieldset-row"
          >
            <div className="form-unit">
              <SelectCompany
                companies={this.props.companies}
                inputName={`market_demand_questionnaire[fundinno_stock_sell_wishes_attributes][${field.id}][company_id]`}
                className="company-select"
                defaultCompanyId={field.companyId}
                placeholder="保有されている銘柄"
              />
              <span className="sell-form-caption">を購入時と比べて</span>
              <Input
                name={`market_demand_questionnaire[fundinno_stock_sell_wishes_attributes][${field.id}][wished_sell_price_level]`}
                className="wished-sell-price"
                type="select"
                placeholder="select"
                defaultValue={field.wishedSellPrice || ''}
                onKeyDown={this.onKeyDown}
              >
                <option value="" />
                {Object.keys(wishedSellPrices).map((e, i) => (
                  <option key={i} value={e}>
                    {wishedSellPrices[e]}
                  </option>
                ))}
              </Input>
              <span className="sell-form-caption">売りたい。</span>
            </div>
            <button
              type="button"
              onClick={() => this.onRemoveField(field.id)}
              className="icon-button"
            >
              <i className="fas fa-minus-circle" />
            </button>
          </fieldset>
        ))}
        <button
          type="button"
          onClick={() => this.onAddField()}
          className="icon-button"
        >
          <i className="fas fa-plus-circle" />
        </button>
      </div>
    );
  }
}
