// @flow
import React from 'react';
import { Input } from 'reactstrap';

type Field = {
  id: number,
  companyName: ?string,
};

type BuyWish = {
  companyName: string,
};

type Props = {
  defaultData: Array<BuyWish>,
};

type State = {
  fields: Array<Field>,
};

export default class InputOtherVentureCompaniesView extends React.Component<
  Props,
  State,
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: this.convertToFields(props.defaultData),
    };
  }

  onAddField() {
    const { fields } = this.state;
    let nextID = 0;
    if (fields.length > 0) {
      nextID = fields[fields.length - 1].id + 1;
    }

    this.setState({
      fields: [
        ...this.state.fields,
        {
          id: nextID,
          companyName: null,
        },
      ],
    });
  }

  onRemoveField(id: number) {
    this.setState({
      fields: [...this.state.fields.filter(c => c.id !== id)],
    });
  }

  onKeyDown = (event: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  convertToFields(buyWishes: Array<BuyWish>): Array<Field> {
    return buyWishes.map((buyWish, i) => ({
      id: i,
      companyName: buyWish.companyName,
    }));
  }

  render() {
    const { fields } = this.state;

    return (
      <div className="input-buy-form-wrapper">
        {fields.map(field => (
          <fieldset
            key={field.id}
            id={`othre_company_to_buy_${field.id}`}
            className="fieldset-row"
          >
            <Input
              name={`market_demand_questionnaire[other_venture_stock_wishes_attributes][${field.id}][company_name]`}
              className="company-name-input"
              type="text"
              defaultValue={field.companyName}
              placeholder="その他ベンチャー企業の銘柄"
              onKeyDown={this.onKeyDown}
            />
            <button
              type="button"
              onClick={() => this.onRemoveField(field.id)}
              className="icon-button"
            >
              <i className="fas fa-minus-circle" />
            </button>
          </fieldset>
        ))}
        <button
          type="button"
          onClick={() => this.onAddField()}
          className="icon-button"
        >
          <i className="fas fa-plus-circle" />
        </button>
      </div>
    );
  }
}
